import { getUrlTrackingParams } from './functions';
import { hasStorageAvailable } from './storage';

const gclsrcIsValid = (gclsrc?: string) => {
  if (typeof gclsrc === 'string' || gclsrc === null || gclsrc === undefined) {
    return !gclsrc || gclsrc.indexOf('aw') !== -1;
  }

  return false;
};

export const saveGclidToLocalStorage = (gclidValue: string, gclsrc: string) => {
  if (gclidValue && gclsrcIsValid(gclsrc) && hasStorageAvailable('localStorage')) {
    const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds

    const gclidRecord = {
      value: gclidValue,
      expiryDate: new Date().getTime() + expiryPeriod,
    };

    localStorage.setItem('gclid', JSON.stringify(gclidRecord));
  }
};

export const readGclidFromLocalStorage = () => {
  if (hasStorageAvailable('localStorage')) {
    const gclidRecord = localStorage.getItem('gclid');
    if (gclidRecord) {
      const parsedRecord = JSON.parse(gclidRecord);
      const currentTime = new Date().getTime();
      if (parsedRecord && parsedRecord.expiryDate && (currentTime < parsedRecord.expiryDate)) {
        return parsedRecord.value;
      }
    }
  }

  return '';
};

export const handleGclid = () => {
  const { gclid, gclsrc } = getUrlTrackingParams();

  if (gclid) {
    saveGclidToLocalStorage(gclid, gclsrc);
  }
};
